import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { navigate } from "gatsby";

function NotFoundPage() {
  return (
    <Layout mainClass="bg-gray-200 flex">
      <SEO title="404: Not found" />
      <section className="px-8 p-4 m-auto md:px-8 flex flex-col">
        <h2 className="text-center text-4xl uppercase p-3">
          Sorry, Pagina niet gevonden!
        </h2>
        <button
          onClick={() => navigate("/")}
          className="bg-blue-branding hover:bg-blue-900 uppe text-white font-bold rounded-lg my-4 py-4 px-8 shadow-lg mx-auto"
        >
          Naar Startpagina
        </button>
      </section>
    </Layout>
  );
}

export default NotFoundPage;
